import Link from "next/link";
import FooterEmail from "./FooterEmail";
import Links from "@/app/constants/links";
import FooterTelegramLink from "./FooterTelegramLink";

export interface FooterLinkProps {
    title: string;
    href: string;
    target?: string;
    email?: boolean;
};

export default function FooterLink(props: FooterLinkProps) {

    return (
        <li 
            className="text-neutral-700 hover:text-rose-600 
            hover:font-semibold transition-all ease-in-out duration-300
            dark:text-neutral-300 dark:hover:text-rose-500"
        >
            {props.email ? (
                <FooterEmail
                    title={props.title}
                    href={props.href}
                />
            ) : props.href === Links.TELEGRAM_BOT ? (
                <FooterTelegramLink title={props.title} />
            ) : (
                <Link href={props.href} target={props.target}>
                    {props.title}
                </Link>
            )}
        </li>
    );
}
