"use client"

import { useEffect, useState } from "react";
import Link from "next/link";
import Links from "@/app/constants/links";
import LocalStorage from "@/app/constants/localStorage";

interface FooterTelegramLinkProps {
    title: string;
};

export default function FooterTelegramLink(props: FooterTelegramLinkProps) {
    const [link, setLink] = useState<string>(Links.TELEGRAM_BOT);

    useEffect(() => {
        const referrerId: string | null = localStorage.getItem(
            LocalStorage.REFERRER_ID
        );
        if (!referrerId) return;
        setLink(`${Links.TELEGRAM_BOT}?${LocalStorage.START}=${referrerId}`);
    }, []);
    
    return (
        <Link href={link} target="_blank">
            {props.title}
        </Link>
    );
}
