export default class Translations {
    static readonly COMMON = "common";
    static readonly HEADER = "header";
    static readonly FOOTER = "footer";
    static readonly HOME = "home";
    static readonly BLOG = "blog";
    static readonly AUTH = "authentication";
    static readonly DASHBOARD = "dashboard";
    static readonly NOT_FOUND = "not-found";
    static readonly LEGAL = "legal";
    static readonly MESSAGE = "message";
    static readonly FAQ = "frequently-asked-questions";
}
